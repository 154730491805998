import React, {Component} from "react";
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    OverlayView
} from "react-google-maps";
import SVG from "react-inlinesvg";
import mapEmptySvg from "../static/images/mapa-empty.svg";
import mapSbEmptySvg from "../static/images/sb-plan.svg";
import s1 from "../static/images/s1.svg";
import s2 from "../static/images/s2.svg";
import s3 from "../static/images/s3.svg";
import s4 from "../static/images/s4.svg";
import s5 from "../static/images/s5.svg";
import s62 from "../static/images/s6.svg";
import s6 from "../static/images/s6s.svg";
import s7 from "../static/images/s7.svg";
import s8 from "../static/images/s8.svg";
import sb1 from "../static/images/sb1.svg";
import n1 from "../static/images/nova-polhora.svg";
import n2 from "../static/images/beniakovce.svg";
import legend from "../static/images/legend-sk.svg";
import legendEn from "../static/images/legend-en.svg";
import "./OverlayMap.css";
import mapStyle from "./mapStyle.js";
import ModalSector from "../components/modalSector";

const defaultOptions = {
    styles: mapStyle,
    zoomControl: false,
    mapTypeControl: false,
    scaleControl: true,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: true,
    gestureHandling: "greedy",
    scrollwheel: false
};

const bulding = { 
    s7b: { 
        name: "Stavba S8", 
        area: "128 000 m",
        building: "50 826 m",
        status: "Rezervované",
        sentence1: "Areál je rezervovaný do 30.05.2022",
    },
};






class MapWithAnOverlayView extends Component {
    constructor(props) {
        super(props);

        this.state = {isOpen: false, selectedBulding: null};
        this.boxS2AOpen = (idecko) => this.props.onSelect(idecko);
        // this.isOpen = (idecko) => this.props.isOpen(idecko);

    }


    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.isOpen !== this.state.isOpen || nextState.selectedBulding !== this.state.selectedBulding) {
                 return true;
        }
        return false;
    }

    openModal(selected) {
        this.setState({
            isOpen: !this.state.isOpen,
            selectedBulding: selected
        });
    }

    render() {
        return (
            <div>
                <ModalSector name="S1" isOpen={this.state.isOpen} building={bulding[this.state.selectedBulding]} toggle={() => this.setState({isOpen: !this.state.isOpen})}/>
                <GoogleMap
                    defaultZoom={15}
                    defaultCenter={{lat: 48.83254, lng: 21.312025}}
                    defaultOptions={defaultOptions}
                >
                    <OverlayView
                        bounds={{
                            ne: {lat: 1.002779, lng: 21.307995},
                            sw: {lat: 48.837811, lng: 21.313185}
                        }}
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    >
                        <div style={{backgroundColor: "black"}}>
                            <SVG
                                src={mapSbEmptySvg}
                                style={{width: "100%", height: "100%"}} className="float-left"
                                onError={error => console.log(error.message)}
                            />
                        </div>
                    </OverlayView>
                    <OverlayView
                        bounds={{
                            ne: {lat: 64.802779, lng: 21.308275},
                            sw: {lat: 48.834611, lng: 21.322085}
                        }}
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    >
                        <div>
                            <SVG
                                src={mapEmptySvg}
                                style={{width: "100%", height: "100%"}} className="float-left"
                                onError={error => console.log(error.message)}
                            />
                        </div>
                    </OverlayView>
                    <OverlayView
                        bounds={{
                            ne: {lat: 66.090779, lng: 21.310975},
                            sw: {lat: 48.833711, lng: 21.333985}
                        }}
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    >
                        <div>
                            <SVG
                                src={s1}
                                style={{width: "10%", height: "auto"}} className="float-left desaturate"
                                onError={error => console.log(error.message)}
                                onMouseEnter={() => this.boxS2AOpen("S2A")}
                                onClick={() => {window.location.href = 'https://www.npi.sk'}}
                                // onMouseEnter={props.this.boxS2AOpen}
                            />
                        </div>
                    </OverlayView>
                    <OverlayView
                        bounds={{
                            ne: {lat: 66.090779, lng: 21.31205},
                            sw: {lat: 48.834281, lng: 21.335985}
                        }}
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    >
                        <div>
                            <SVG
                                src={s2}
                                style={{width: "10%", height: "auto"}} className="float-left desaturate"
                                onError={error => console.log(error.message)}
                                onMouseEnter={() => this.boxS2AOpen("S2B")}
                                onClick={() => {window.location.href = 'https://www.npi.sk'}}
                            />
                        </div>
                    </OverlayView>
                    <OverlayView
                        bounds={{
                            ne: {lat: 66.090379, lng: 21.30972},
                            sw: {lat: 48.830281, lng: 21.330085}
                        }}
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    >
                        <div>
                            <SVG
                                src={s4}
                                style={{width: "10%", height: "auto"}} className="float-left desaturate"
                                onError={error => console.log(error.message)}
                                onMouseEnter={() => this.boxS2AOpen("S3")}
                                onClick={() => {window.location.href = 'https://www.npi.sk'}}
                            />
                        </div>
                    </OverlayView>
                    <OverlayView
                        bounds={{
                            ne: {lat: 66.090779, lng: 21.31245},
                            sw: {lat: 48.831381, lng: 21.350985}
                        }}
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    >
                        <div>
                            <SVG
                                src={s3}
                                style={{width: "10%", height: "auto"}} className="float-left desaturate"
                                onError={error => console.log(error.message)}
                                onMouseEnter={() => this.boxS2AOpen("S4")}
                                onClick={() => {window.location.href = 'https://www.npi.sk'}}
                            />
                        </div>
                    </OverlayView>
                    <OverlayView
                        bounds={{
                            ne: {lat: 66.090379, lng: 21.31539},
                            sw: {lat: 48.827681, lng: 21.340085}
                        }}
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    >
                        <div>
                            <SVG
                                src={s5}
                                style={{width: "10%", height: "auto"}} className="float-left desaturate"
                                onError={error => console.log(error.message)}
                                onMouseEnter={() => this.boxS2AOpen("S5")}
                                onClick={() => {window.location.href = 'https://www.npi.sk'}}
                            />
                        </div>
                    </OverlayView>
                    <OverlayView
                        bounds={{
                            ne: {lat: 66.090379, lng: 21.31495},
                            sw: {lat: 48.826581, lng: 21.358085}
                        }}
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    >
                        <div>
                            <SVG
                                src={s6}
                                style={{width: "10%", height: "auto"}} className="float-left desaturate"
                                onError={error => console.log(error.message)}
                                onMouseEnter={() => this.boxS2AOpen("S6")}
                                onClick={() => {window.location.href = 'https://www.npi.sk'}}
                            />
                        </div>
                    </OverlayView>
                    <OverlayView
                        bounds={{
                            ne: {lat: 66.090379, lng: 21.31654},
                            sw: {lat: 48.824981, lng: 21.342085}
                        }}
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    >
                        <div>
                            <SVG
                                src={s7}
                                style={{width: "10%", height: "auto"}} className="float-left desaturate"
                                onError={error => console.log(error.message)}
                                onMouseEnter={() => this.boxS2AOpen("S7A")}
                                onClick={() => {window.location.href = 'https://www.npi.sk'}}
                            />
                        </div>

                    </OverlayView>
                    <OverlayView
                        bounds={{
                            ne: {lat: 66.090379, lng: 21.31814},
                            sw: {lat: 48.825581, lng: 21.35185}
                        }}
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    >
                        <div>
                            <SVG
                                src={s8}
                                style={{width: "10%", height: "auto"}} className="float-left desaturate"
                                onError={error => console.log(error.message)}
                                onMouseEnter={() => this.boxS2AOpen("S7B")}
                                onClick={() => {window.location.href = 'https://www.npi.sk'}}
                            />
                        </div>

                    </OverlayView>
                    <OverlayView
                        bounds={{
                            ne: {lat: 66.132379, lng: 21.32414},
                            sw: {lat: 48.832581, lng: 21.369085}
                        }}
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    >
                        <div>
                            <SVG
                                src={this.props.location.pathname.includes("/en/") ? legendEn : legend }
                                style={{width: "25%", height: "auto"}}
                                onError={error => console.log(error.message)}
                                // onMouseEnter={() => this.boxS2AOpen("7B")}
                            />

                        </div>
                    </OverlayView>
                    <OverlayView
                        bounds={{
                            ne: {lat: 64.802779, lng: 21.317275},
                            sw: {lat: 48.831611, lng: 21.332085}
                        }}
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    >
                        <div>
                            <SVG
                                src={n1}
                                style={{width: "50%", height: "50%"}} className="float-left"
                            />
                        </div>
                    </OverlayView>
                    <OverlayView
                        bounds={{
                            ne: {lat: 64.802779, lng: 21.292275},
                            sw: {lat: 48.826611, lng: 21.307085}
                        }}
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    >
                        <div>
                            <SVG
                                src={n2}
                                style={{width: "50%", height: "50%"}} className="float-left"
                            />
                        </div>
                    </OverlayView>
                    <OverlayView
                        bounds={{
                            ne: {lat: 48.833999, lng: 21.307995},
                            sw: {lat: 48.837899, lng: 21.360000}
                        }}
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    >
                        <div >
                            <SVG
                                src={sb1}
                                style={{width: "10%", height: "auto"}} className="float-left"
                                onError={error => console.log(error.message)}
                                onMouseEnter={() => this.boxS2AOpen("S6")}
                                onClick={() => this.openModal("s7b")}
                            />
                        </div>
                    </OverlayView>
                </GoogleMap>
            </div>
        );
    }
}

export default withScriptjs(withGoogleMap(MapWithAnOverlayView));
